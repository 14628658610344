/* global  utils */
const GDD_BASE_TEMP = 7;

/**
 * calculate co2 influence factor on light requirement
 * @param {number} co2            co2 value
 * @param {number} co2_offset     co2_offset
 * @param {number} co2_slope      co2_slope
 * @returns {number}              influence factor of co2 on light requirement
 */
function co2_factor(co2, co2_offset, co2_slope) {
  // co2 setpoint based on https://www.climate.gov/media/13611
  return (1.0 - Math.exp(-1 / co2_slope * 3 / 1000 * Math.max(co2 - co2_offset, 0.01))) /
          (1.0 - Math.exp(-1 / co2_slope * 3 / 1000 * (420 - co2_offset)));
}

/**
 * Returns the most fitting function for interpolating the optimal temperature values from ligth values
 * based on the length of provided arrays
 * @param {number[]} optimal_available_light    Array of setpoints for optimal light corresponding to temperature
 * @param {number[]} optimal_temp               Array of setpoints for optimal temperature corresponding to light
 * @returns {function}                          Interpolation function for given arrays
 */
function temp_setpoint_interpolator(optimal_available_light, optimal_temp) {
  const filtered_list = utils.filter_duplicates(optimal_available_light, optimal_temp);
  const indices = filtered_list[0].map((_, idx) => idx);
  indices.sort((a, b) => filtered_list[0][a] - filtered_list[0][b]);
  const xs = indices.map((idx) => filtered_list[0][idx]);
  const ys = indices.map((idx) => filtered_list[1][idx]);

  // If input data is given, calculate the setpoint for the temperature
  // dependence by interpolating or extrapolating between the known setpoints
  // at other light intensities. x = intensity, y = temperature
  let interpolator = null;
  if (xs.length >= 2 && ys.length >= 2) {
    // only works (and only makes sense) with multiple values
    interpolator = function(T) {
      const linear = require('everpolate').linear;
      return linear(T, xs, ys);
    };
  } else if ( ys.length == 1 && ys[0] != null) {
    interpolator = function(T) {
      return Array(T.length).fill(ys);
    };
  } else {
    interpolator = function(T) {
      return Array(1).fill(null);
    };
  }
  return interpolator;
}

/**
 * Calculate the temperature influence factor on light requirement
 * @param {number} T                Temperature value
 * @param {number} E                Incoming light value
 * @param {Object} plant_profile    Plant profile Object
 * @returns {number}                Temperature influence factor on light requirement
 */
function temperature_factor(T, E, plant_profile) {
  const interp = temp_setpoint_interpolator(
      plant_profile.optimal_available_light,
      plant_profile.optimal_temp);
  const T0 = interp(E)[0];
  return temperature_factor_raw(T, T0, plant_profile);
}

/**
 * Calculate the temperature influence factor on light requirement
 * @param {number} T                Temperature value
 * @param {number} T0               Incoming light value
 * @param {Object} plant_profile    Plant profile Object
 * @returns {number}                Temperature influence factor on light requirement
 */
function temperature_factor_raw(T, T0, plant_profile) {
  // The temperature plot is divided in 3 domains: 2 sigmoid functions on the side and
  // a polynomial in the middle. They are matched at the setpoint and a chosen
  // temperature in both their value and gradient for a smooth function. The function
  // parameters were obtained by solving the linear system of the equations in python
  // using the sympy module. Matching value is 0.98 because the sigmoids have an
  // asymptote at 1.
  // returns a decimal factor
  if (!T0) {
    return 1;
  }

  const lmin = parseFloat(plant_profile.temp_l_min); // linker ondergrens
  const rmin = parseFloat(plant_profile.temp_r_min); // rechter ondergrens
  const g0 = parseFloat(plant_profile.temp_setpoint_gradient); // gradient op setpoint
  const dtm = parseFloat(plant_profile.temp_max_f_diff); // temperatuur met maximum factor
  const dtml = parseFloat(plant_profile.temp_sig_diff); // temperatuur voor omslag sigmoid naar polynomial
  const fm = parseFloat(plant_profile.temp_max_f); // maximum factor

  // polynomial parameters for a x^3 + b x^2 + c x + d
  const a = (dtm * g0 + 2 * fm - 2) / dtm ** 3;
  const b = (2 * dtm ** 2 * g0 + 3 * dtm * fm - 3 * dtm * g0 * T0 - 3 * dtm - 6 * fm * T0 +
    6 * T0) / dtm ** 3;
  const c = (dtm ** 3 * g0 - 4 * dtm ** 2 * g0 * T0 - 6 * dtm * fm * T0 + 3 * dtm * g0 *
    T0 ** 2 + 6 * dtm * T0 + 6 * fm * T0 ** 2 - 6 * T0 ** 2) / dtm ** 3;
  const d = (-1 * dtm ** 3 * g0 * T0 + 1 * dtm ** 3 + 2 * dtm ** 2 * g0 * T0 ** 2 + 3 * dtm * fm *
    T0 ** 2 - 1 * dtm * g0 * T0 ** 3 - 3 * dtm * T0 ** 2 - 2 * fm * T0 ** 3 + 2 * T0 ** 3) / dtm ** 3;

  // sigmoid parameters for right side
  const rfac = 1 * g0 * (50 * rmin - 51.0) / (1 * rmin - 1);
  const tr = (50 * g0 * rmin * T0 - 51 * g0 * T0 - rmin * Math.log(-1 / (rmin - 1)) + 3.912023 *
    rmin + Math.log(-1 / (rmin - 1)) - 3.912023) / (g0 * (50 * rmin - 51));

  // efficiency factor and gradient values for transition point from left sigmoid to polynomial
  const fl = a * (T0 - dtml) ** 3 + b * (T0 - dtml) ** 2 + c * (T0 - dtml) + d;
  const gl = a * 3 * (T0 - dtml) ** 2 + b * 2 * (T0 - dtml) + c;

  // sigmoid parameters for left side
  const lfac = gl * (-fm + lmin) / (fl ** 2 - fl * fm - fl * lmin + fm * lmin);
  const tl = (-dtml * fm * gl + dtml * gl * lmin - fl ** 2 * Math.log((-fl + fm) / (fl - lmin)) + fl * fm *
    Math.log((-fl + fm) / (fl - lmin)) + fl * lmin * Math.log((-fl + fm) / (fl - lmin)) + fm * gl * T0 - fm * lmin *
    Math.log((-fl + fm) / (fl - lmin)) - gl * lmin * T0) / (gl * (fm - lmin));

  if (T <= T0 - dtml) {
    return lmin + (fm - lmin) / (1 + Math.exp(- lfac * (T - tl)));
  } else if (T > T0 - dtml && T <= T0) {
    return a * T ** 3 + b * T ** 2 + c * T + d;
  } else {
    return rmin + (1.02 - rmin) / (1 + Math.exp(rfac * (T - tr)));
  }
}

// instead of passing in plan I think it is nicer to pass in exactly the arguments needed
// so we don't have the distinction between plan and realized..
// we might have to make a helper function to merge realized and plan if we want to do this on the realized page
/**
 * Calculate artificial light in joules from lighting hours
 * @param {number[]} lighting_hours   Array of articifial lighting hours
 * @param {Object} light              Light object
 * @returns {number[]}                Array of articifial light in joules
 */
function lighting_joules_single(lighting_hours, light) {
  const lighting_hours_var = utils.fill_nulls(lighting_hours);
  let power = 0;
  let efficiency = 1;
  if (typeof light !== 'undefined') {
    power = parseFloat(light['power']);
    efficiency = parseFloat(light['efficiency']);
  }
  return lighting_hours_var.map((e, i) => e * power / efficiency);
}

function lighting_joules_total(...args) {
  return args[0].map((e, i) => utils.sum_arrays(args, i));
}

/**
 * Calculate the effective light inside a greenhouse coming from outside light
 * @param {Object} plan   Plan object
 * @param {Object} crop   Crop object
 * @returns {number[]}    Effective light from outside
 */
function outside_light_inside(plan, crop) {
  // outside light is given in joules/day/cm2
  const outside_light = utils.fill_nulls(plan['outside_light']);
  const transmission_percent_var = parseFloat(crop['transmission_percent']);
  const coating_var = crop.coating_switch ? utils.fill_nulls(plan.coating) : Array(plan.coating.length).fill(0.0);
  return outside_light.map((e, i) => e * transmission_percent_var / 100 * (100 - coating_var[i]) / 100);
}

/**
 * Calculate corrected light requirement (joules of light per squared cm required for every
 * gram of plant growth per squared meter). Takes the effects of co2 and temperature into account
 * @param {number[]} temperature        Temperature array
 * @param {number[]} co2                Co2 array
 * @param {number[]} light              Light value in joules
 * @param {number[]} efficiency_factor  Grower Efficiency factor of plan (performance fudge factor per grower)
 * @param {Object} plant_profile        Plant profile efficiency
 * @returns {number[]}                  Corrected light efficiency array
 */
function light_requirement_corrected(temperature, co2, light, efficiency_factor, plant_profile) {
  temperature = utils.fill_nulls(temperature);
  const light_requirement = plant_profile['light_requirement'];
  co2 = utils.fill_nulls(co2);
  return utils.fill_nulls(efficiency_factor).map((eff_fac, i) => {
    // TODO: include light dependence here
    return light_requirement / (eff_fac / 100) /
      (co2_factor(co2[i], plant_profile.co2_offset, plant_profile.co2_slope) *
      temperature_factor(temperature[i], light[i], plant_profile));
  });
}

/**
 * Calculate the Joules needed in each week based on the setting and the corrected light requirement
 * Do this by iterating over the input weeks and keeping track per output week (i.e. reduce)
 * fruit_set and light_requirement need to have their nulls filled before entry
 * The joules needed are given by the weight (planned per start-week) divided
 * by the grow_period, equally distributed over the weeks
 * @param {number[]} weight                       Weight array
 * @param {number[]} grow_period                  Grow period array
 * @param {number[]} fruit_set                    Fruit set array
 * @param {number[]} light_requirement_corrected  Corrected light requirement array
 * @returns {number[]}                            Energy needed array
 */
function energy_needed(weight, grow_period, fruit_set, light_requirement_corrected) {
  weight = utils.fill_nulls(weight);
  grow_period = utils.fill_nulls(grow_period);
  return fruit_set.reduce((acc, fruit_set, i) => {
    // In week i we distribute the joules needed over the days of grow_period
    const grams_per_day = fruit_set * weight[i] / grow_period[i];
    // except the light efficiency, which we calculate in the week itself
    // (replace i+j in light_requirement[i+j] by i to undo that)
    // full contributions:
    for (let j = 0; j < Math.floor(grow_period[i] / 7); j++) {
      acc[i + j] += grams_per_day * light_requirement_corrected[i + j];
    }
    // partial contribution
    const j = Math.floor(grow_period[i] / 7);
    acc[i + j] += (grow_period[i] % 7) / 7 * grams_per_day * light_requirement_corrected[i + j];
    return acc;
  }, Array(fruit_set.length).fill(0)).slice(0, fruit_set.length);
}

/**
 * The effective stem_density is calculated by pushing the registered stem_density
 * values from the plan forward by the stem_density_delay values
 * | week | s_d | delay | eff_s_d |
 * |   1  |  1  |   0   |    1    |
 * |   2  |  2  |   1   |    1    |
 * |   3  |  3  |   1   |    2    |
 * @param {Object} plan   Plan object
 * @returns {number[]}    Stem density array
 */
function calc_stem_density(plan) {
  const plan_stem_density = utils.fill_nulls(plan['stem_density']);
  const stem_density_delay = utils.fill_nulls(plan['stem_density_delay']);
  let effective_stem_density = Array(plan_stem_density.length).fill(null);
  for (let i = 0; i < stem_density_delay.length; i++) {
    const delay = Math.max(stem_density_delay[i], 0);
    const idx = i + delay;
    if (idx < plan_stem_density.length) {
      effective_stem_density[idx] = plan_stem_density[i];
    }
  }
  effective_stem_density = utils.fill_nulls(effective_stem_density);
  return effective_stem_density;
}


/**
 * Calculate the fruit setting based on the flowering speed, stem density and the pruning policy
 * Uses a hybrid of the realized and plan data where the realized values are
 *  pasted over the plan values. In the case that no second argument is given or an empty array
 *  is given it will simply use the initial plan values.
 * @param {Object} crop       Crop object
 * @param {Object} plan       Plan object
 * @param {Object} realized   Realized object
 * @returns {number[]}        Fruit set array
 */
function fruit_set(crop, plan, realized = null) {
  let res;
  if (crop['composite_count'] && 'fruit_set' in plan) {
    res = utils.fill_nulls(plan['fruit_set']);
  } else {
    let stem_density = calc_stem_density(plan);
    let flowering_speed = utils.fill_nulls(plan['flowering_speed']);
    let pruning_policy = utils.fill_nulls(plan['pruning_policy']);
    // for each value in the realized data which is not null overwrite the corresponding value in stem_density
    if (!!realized && realized['stem_density'] && realized['flowering_speed'] && realized['pruning_policy']) {
      stem_density = utils.paste_over(stem_density, realized['stem_density']);
      flowering_speed = utils.paste_over(flowering_speed, realized['flowering_speed']);
      pruning_policy = utils.paste_over(pruning_policy, realized['pruning_policy']);
    }
    res = stem_density.map((e, i) =>
      e * flowering_speed[i] * pruning_policy[i]
    );
  }

  const path = handlePageLoad();
  if (!path.includes('new') && path.includes('realized') && crop['use_registration_app']) {
    res = utils.paste_over(res, plan['fruit_set']);
  }

  if (realized != null && 'fruit_set' in realized) {
    res = utils.paste_over(res, realized['fruit_set']);
  }
  return res;
}

function handlePageLoad() {
  const path = window.location.pathname;
  return path;
}

document.addEventListener('DOMContentLoaded', handlePageLoad);
document.addEventListener('turbolinks:load', handlePageLoad);
document.addEventListener('turbo:load', handlePageLoad);

/**
 * The plant load is defined as the setting which has not been harvested yet (in pieces)
 * @param {number[]} fruit_set      Fruit set array
 * @param {number[]} grow_period    Grow period array
 * @returns {number[]}              Plant load array
 */
function plant_load(fruit_set, grow_period) {
  fruit_set = utils.fill_nulls(fruit_set);
  grow_period = utils.fill_nulls(grow_period);
  return fruit_set.reduce((acc, set, i) => {
    // The plant load for a week is defined as the load at the start of the week
    //
    // We can get this by calculating which fraction of each start_week
    // has not yet reached the grow_period at the start of the current week
    // (i.e. x0 + i + grow_period < x1 for i from 0 to 7)
    // the impulse response thus has this shape:
    // 1 |   ___________
    //   |  /           \
    //   | /             \
    // 0 |/               \
    //   0             ^ grow_period
    // where the first segment is 7 days (the setting distribution)
    // and the final segment is also 7 days.
    //
    // We can describe this as a trapezium with
    // step 7 and FWHM of grow_period
    //
    // Each of the weeks has to integrate over this response.
    //
    // Instead of using a trapezium function we use the 'old' approach
    // which mimics the required_light calculation
    //
    for (let j = 0; j < Math.floor(grow_period[i] / 7); j++) {
      acc[i + j] += set;
    }
    // partial contribution
    const j = Math.floor(grow_period[i] / 7);
    acc[i + j] += (grow_period[i] % 7) / 7;
    return acc;
  }, Array(fruit_set.length).fill(0)).slice(0, fruit_set.length);
}

/**
 * Calculate the total effective sum of artificial light and incoming sun light
 * @param {Object} plan                     Plan object
 * @param {Object} crop                     Crop object
 * @param {Object} plant_profile            Plant profile object
 * @param {number[]} lighting_joules_total  Total artificial light array
 * @param {boolean} no_max                     Whether or not to use the maximum usable light in the calculation
 * @returns {number[]}                      Effective sum of light
 */
function total_light(plan, crop, plant_profile, lighting_joules_total, no_max = false) {
  const outside_light = outside_light_inside(plan, crop);
  // this is a little ugly, but there is no numpy-like array + method
  if (no_max) {
    return lighting_joules_total.map((e, i) => e + outside_light[i]);
  } else {
    return lighting_joules_total.map((e, i) => Math.min(e + outside_light[i], plant_profile['maximum_light_use']));
  }
}

/**
 * Calculate the weight of crop ready in each week
 * by taking the setting at each week, spreading it over the days
 * and multiplying by the weight of fruits set in that week
 * @param {number[]} fruit_set        Fruit set array
 * @param {number[]} weight           Fruit weight array
 * @param {number[]} grow_period      Grow period array
 * @param {number} loss_percentage    Standard loss percentage of crop
 * @returns {number[]}                Predicted harvest in kilograms
 */
function predicted_harvest_kg(fruit_set, weight, grow_period, loss_percentage) {
  weight = utils.fill_nulls(weight); // in grams
  grow_period = utils.fill_nulls(grow_period);
  return fruit_set.reduce((acc, set, i) => {
    // We distribute over the days by calculating which fraction of
    // fruits set in week i end up in week i+j.
    //
    // Define an overlap fraction f which indicates which part of the production
    // of week i ends up in week i+j as
    // f(i,i+j) = triangle(grow_period[i] - 7*j, 7)
    // where triangle(x, 7) is 1 at x=0 and 0 at x=7
    for (let j = 0; j < fruit_set.length; j++) {
      acc[i + j] += utils.triangle(grow_period[i] - j * 7, 7) * weight[i] / 1000 * set * (100 - loss_percentage) / 100;
    }
    return acc;
  }, Array(fruit_set.length).fill(0)).slice(0, fruit_set.length);
}

/**
 * Calculate predicted harvest in pieces
 * @param {number[]} fruit_set        Fruit set array
 * @param {number[]} grow_period      Grow period array
 * @param {number} loss_percentage    Standard loss percentage of crop
 * @returns {number[]}                Predicted harvest in pieces
 */
function predicted_harvest_pieces(fruit_set, grow_period, loss_percentage) {
  grow_period = utils.fill_nulls(grow_period);
  return fruit_set.reduce((acc, set, i) => {
    // We distribute over the days by calculating which fraction of
    // fruits set in week i end up in week i+j.
    //
    // Define an overlap fraction f which indicates which part of the production
    // of week i ends up in week i+j as
    // f(i,i+j) = triangle(grow_period[i] - 7*j, 7)
    // where triangle(x, 7) is 1 at x=0 and 0 at x=7
    for (let j = 0; j < grow_period.length; j++) {
      acc[i + j] += utils.triangle(grow_period[i] - j * 7, 7) * set * (100 - loss_percentage) / 100;
    }
    return acc;
  }, Array(fruit_set.length).fill(0)).slice(0, fruit_set.length);
}

/**
 * Calculate a rough ratio of the week harvest to the total harvest
 * by subtracting the grow_period from the total number of weeks
 * @param {number[]} grow_period    Grow period array
 * @param {number} duration         Crop duration value
 * @returns {number}                Ratio of weekly harvest to total
 */
function ratio_of_week_harvest_to_total(grow_period, duration) {
  const x = duration - utils.avg(grow_period) / 7;
  return Math.ceil(x / 10) * 10;
}

/**
 * temporary function for realized values until a better solution is available
 * TODO: more futureproof solution
 * @param {Object} plan       Plan object
 * @param {Object} realized   Realized object
 * @returns {Object}          Updated realized object
 */
function realized_calcs(plan, realized) {
  realized.grow_period = plan.grow_period; // as per request issue #503
  realized.weight = plan.weight; // as per request issue #503
  realized.efficiency_factor = plan.efficiency_factor;
  realized.stem_density_delay = Array(plan.stem_density.length).fill(null);
  const last_realized_idx = last_idx(plan.stem_density.length, realized);
  for (let i = 0; i < last_realized_idx; i++) {
    realized.stem_density_delay[i] = 0;
  }
  return realized;
}

/**
 * Paste realized values over plan values
 * @param {Object} plan         Plan object
 * @param {Object} realized     Realized object
 * @param {Object} linear       Object describing which interpolation method should be used per attribute
 * @returns {Object}            Hybrid object with realized values pasted over plan values
 */
function hybrid_paste_over(plan, realized, linear) {
  const hybrid = {};
  Object.keys(plan).map((k) => {
    hybrid[k] = utils.paste_over(utils.interp(plan[k], linear[k]), realized[k]);
  });
  const h_keys = Object.keys(hybrid);
  const n = hybrid[Object.keys(hybrid)[0]].length;
  Object.keys(realized).map((k) => {
    if (!h_keys.includes(k)) {
      hybrid[k] = realized[k].slice();
      hybrid[k].length = n;
      hybrid[k].fill(null, realized[k].length, n);
    }
  });
  return hybrid;
}

/**
 * calculates the total sum of (T_day - T_base) in °C*day
 * which can also be interpreted as (T_avg - T_base) * n_days
 * @param {Object} plan            Plan object
 * @param {Object} linear          Use linear_interpolation if linear.temperature is true, firstValueUp if false
 * @returns {Object}               Updated plan object
 */
function get_gdd(plan, linear) {
  const gdd = [];
  let runningSum = 0;
  const temp = utils.interp(plan.temperature, linear.temperature);
  const tb = GDD_BASE_TEMP;
  for (let i = 0; i < plan.temperature.length; i++) {
    runningSum += 7 * (temp[i] - tb);
    gdd.push(runningSum);
  }
  return gdd;
}

/**
 * Calculates the grow period based on a preset amount of growing degree days and the calculated
 * number of degree days over time
 * @param {Object} plan            Plan object
 * @param {Object} linear          Use linear_interpolation if true, firstValueUp if false
 * @param {Object} plant_profile   PlantProfile object
 * @returns {Object}               Updated plan object
 */
function calc_grow_period(plan, linear, plant_profile) {
  const n = plan.temperature.length;
  const temp = utils.interp(plan.temperature, linear.temperature);
  const gdd = get_gdd(plan, linear);
  const degree_days = plant_profile.degree_days ||
                      degree_days_from_weight(plant_profile.expected_weight, plant_profile.species);
  const grow_period = [];
  let grow_period_i = 0;
  let rel_overshoot;
  for (let i = 0; i < n; i++) {
    let target_found = false;
    for (let j = i + 1; j < n; j++) {
      if (gdd[j] - gdd[i] >= degree_days) {
        // gdd[i]   gdd[i+1]                  gdd[j-1]    gdd[j]
        //   |---------|---------|--.............--|---x-----|
        //                                    (gdd[i]+degree_days)
        rel_overshoot = (gdd[i] + degree_days - gdd[j - 1]) / (gdd[j] - gdd[j - 1]);
        grow_period_i = utils.round(7 * (j - i - 1 + rel_overshoot));
        target_found = true;
        break;
      }
    }
    if (target_found != true) {
      const leftover = degree_days - (gdd.at(-1) - gdd[i]);
      grow_period_i = utils.round(7 * (n - 1 - i + 1) + leftover / Math.max(temp[n - 1], 0.5));
    }
    grow_period.push(grow_period_i);
  }
  return grow_period;
}

/**
 * Calculate the derived values of the crop plan (energy needed, expected harvest, etc.)
 * based on the registered values (temperature, co2, etc.)
 * @param {Object} plan           Plan object
 * @param {Object} linear         Object describing which interpolation method should be used per attribute
 * @param {Object} crop           Crop object
 * @param {Object} plant_profile  Plant profile object
 * @param {Object[]} lights       Array of light objects
 * @returns {Object}              Object describing derived values based on registered values from plan
 */
function derive(plan, linear, crop, plant_profile, lights) {
  const derived = {};
  derived.grow_period = plan.grow_period;
  if (crop.use_degree_days) {
    derived.grow_period = calc_grow_period(plan, linear, plant_profile);
  }
  derived.fruit_set = fruit_set(crop, plan);
  derived.plant_load = plant_load(derived.fruit_set,
      utils.interp(derived.grow_period, linear.grow_period));

  // light supply
  derived.growth_light_1 = lighting_joules_single(utils.interp(plan.lighting_hours_1,
      linear.lighting_hours_1), lights.find((light) => light.light_num == 1));
  derived.growth_light_2 = lighting_joules_single(utils.interp(plan.lighting_hours_2,
      linear.lighting_hours_2), lights.find((light) => light.light_num == 2));
  derived.growth_light_3 = lighting_joules_single(utils.interp(plan.lighting_hours_3,
      linear.lighting_hours_3), lights.find((light) => light.light_num == 3));
  derived.growth_light_total = lighting_joules_total(derived.growth_light_1, derived.growth_light_2,
      derived.growth_light_3);
  derived.energy_available = total_light(plan, crop, plant_profile, derived.growth_light_total);
  derived.energy_available_no_max = total_light(plan, crop, plant_profile, derived.growth_light_total, true);

  // The light efficiency depends on the temperature, the CO2 value, and the amount of light
  // itself.
  derived.light_requirement_corrected = light_requirement_corrected(
      utils.interp(plan.temperature, linear.temperature),
      utils.interp(plan.co2, linear.co2),
      derived.energy_available_no_max,
      utils.interp(plan.efficiency_factor, linear.efficiency_factor),
      plant_profile);
  derived.energy_needed = energy_needed(utils.interp(plan.weight, linear.weight),
      utils.interp(derived.grow_period, linear.grow_period), derived.fruit_set,
      derived.light_requirement_corrected);
  derived.energy_diff = derived.energy_available.map((e, i) => e - derived.energy_needed[i]);
  derived.outside_light_inside = outside_light_inside(plan, crop);
  derived.predicted_harvest_kg = predicted_harvest_kg(derived.fruit_set, utils.interp(plan.weight, linear.weight),
      utils.interp(derived.grow_period, linear.grow_period), crop.loss_percentage);
  derived.predicted_harvest_pieces = predicted_harvest_pieces(derived.fruit_set, utils.interp(derived.grow_period,
      linear.grow_period), crop.loss_percentage);
  Object.keys(derived).map((key) => {
    derived[key] = utils.fill_nulls(derived[key], '');
  });
  return derived;
}

/**
 * Calculates the last filled in index in the realized_rows. This way the graphs know
 * at which week it should stop showing the realization.
 * @param {number} duration   Duration
 * @param {Object} realized   Realized object
 * @returns {number}          Last filled in index of realized rows
 */
function last_idx(duration, realized) {
  // TODO: find more futureproof solution
  // grow_period and weight because they are different in plan and realized
  // efficiency_factor because the array is taken from plan
  // coating because it is filled with zeroes if not turned on
  const exception_list = ['grow_period', 'weight', 'efficiency_factor', 'coating'];

  const n = duration;
  for (let i = n - 1; i >= 0; i--) {
    for (const key in realized) {
      if (realized[key][i] !== null && !exception_list.includes(key)) {
        return i;
      }
    }
  }
  return -1;
}

/**
 * calculate placeholder for amount of degree days necessary for given crop based on weight
 * currently based on T_b of 6 degrees Celsius
 * TODO: update to different T_b values when model is updated
 * @param {number} weight     Expected weight in grams
 * @param {string} species    species of crop
 * @returns {number}          Placeholder for degree days
 */
function degree_days_from_weight(weight, species) {
  if (weight == '') {
    return '';
  }
  let result;
  if (species == 'tomato') {
    const a = 61;
    const b = 2000;
    const min_weight = 25;
    result = Math.round(a * Math.log(b * Math.max(weight, min_weight)));
  }
  return result;
}

// Calculations for making graphs. These must accept an array
// indexed by week_number. Any nulls in inputs should be resolved

const Calc = {
  GDD_BASE_TEMP: GDD_BASE_TEMP,
  calc_grow_period: calc_grow_period,
  calc_stem_density: calc_stem_density,
  co2_factor: co2_factor,
  energy_needed: energy_needed,
  degree_days_from_weight: degree_days_from_weight,
  derive: derive,
  fruit_set: fruit_set,
  get_gdd: get_gdd,
  hybrid_paste_over: hybrid_paste_over,
  last_idx: last_idx,
  light_requirement_corrected: light_requirement_corrected,
  lighting_joules_single: lighting_joules_single,
  lighting_joules_total: lighting_joules_total,
  outside_light_inside: outside_light_inside,
  plant_load: plant_load,
  predicted_harvest_kg: predicted_harvest_kg,
  predicted_harvest_pieces: predicted_harvest_pieces,
  ratio_of_week_harvest_to_total: ratio_of_week_harvest_to_total,
  realized_calcs: realized_calcs,
  temp_setpoint_interpolator: temp_setpoint_interpolator,
  temperature_factor: temperature_factor,
  temperature_factor_raw: temperature_factor_raw,
  total_light: total_light
};

window.calc = Calc;
module.exports = Calc;
