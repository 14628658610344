import Alpine from 'alpinejs';
window.Alpine = Alpine;
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);

/*
 * TODO: move this to it's own file
 * Replace variables occurring between _ _ or [ ] by their values,
 * in all attributes of the current element.
 * The `value` can be used to limit variable expansion to specific values.
 * The `expression` can be used to limit expansion to specific attributes.
 *
 * x-rename:index.name will rename [index] and _index_ to whatever value of `index` is,
 * only in the `name` and `id` attribute of the current element.
 * .name.id is the default.
 *
 * Variables are replaced only once in the string, and only the variables known
 * at initialization time of this directive are expanded.
 *
 * We implement this by defining/overriding x-bind:name etc.
 */
Alpine.directive('rename', (el, {value, modifiers}, {Alpine}) => {
  // which variables to search for
  let vars;
  if (value) {
    vars = value.split(':');
  } else {
    vars = Object.getOwnPropertyNames(Alpine.$data(el));
  }

  // which attributes to expand
  let attrs;
  if (modifiers.length > 0) {
    attrs = modifiers;
  } else {
    attrs = ['name', 'id'];
  }

  attrs.forEach((attribute) => {
    const attr_s = el.attributes[attribute]?.value;
    if (!attr_s) return;

    // construct a x-bind:... string by replacing variables
    let attr_bind = attr_s;
    vars.forEach((variable) => {
      // the approaches below do not support multiple replacements, but putting a raw
      // variable name in a regex is also risky.
      attr_bind = attr_bind.replace(`[${variable}]`, `['+${variable}+']`);
      // for the underscores we could consider a positive lookahead in a regex
      attr_bind = attr_bind.replace(`_${variable}_`, `_'+${variable}+'_`);
    });

    // if any replacements have happened set up an x-bind expression
    if (attr_bind !== attr_s) {
      el.setAttribute('x-bind:' + attribute, '\'' + attr_bind + '\'');

      // ensure AlpineJS rescans the element, since we add an x-bind attribute
      Alpine.initTree(el);
    }
  });
});

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require('turbolinks').start();
require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
require('custom/calc');
require('custom/form_funcs');
require('custom/graphs');
require('custom/init');
require('custom/offline_registration');
require('custom/table');
require('custom/utils');
require('custom/validation_css');
window.Plotly = require('plotly.js-dist');
window.everpolate = require('everpolate');

// This is to include tailwindcss in this pack
// see https://rubyyagi.com/tailwindcss2-rails6/
import 'stylesheets/application.css';

// Could consider only registering controllers we need
import {Chart, Tooltip, registerables} from 'chart.js';
import 'chartjs-adapter-date-fns';

Chart.register(...registerables);
window.Chart = Chart;
// We dispatch this event because the charts are created at load time,
// before Chart.js has been loaded. In case window.Chart does not exist
// a listener for the event below is set up.
document.dispatchEvent(new Event('chartjs:load'));
Alpine.store('dismissed', false);
Alpine.start();

require('tail.select.js/css/default/tail.select-light.css');
const tail_select = require('tail.select.js/js/tail.select');
window.tail_select = tail_select;

// See https://sevos.io/2017/02/27/turbolinks-lifecycle-explained.html for info
// and https://www.honeybadger.io/blog/turbolinks/
// (basically tail_select will be inoperable now until the new page is loaded. fine.)
document.addEventListener('turbolinks:load', () => {
  window.crop_search = tail_select('#crop-select-search', {
    search: true,
    searchFocus: true,
    sourceBind: true,
    classNames: true
  });
  tail_select('.select-search', {
    search: true,
    classNames: true
  });
  tail_select('.multiselect', {
    search: false,
    multiple: true,
    classNames: true
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
const images = require.context('../../assets/images', true);
const imagePath = (name) => images(name, true); // eslint-disable-line no-unused-vars

const Uppy = require('@uppy/core');
const Dashboard = require('@uppy/dashboard');
const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload');

require('@uppy/core/dist/style.css');
require('@uppy/dashboard/dist/style.css');

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('[data-uppy]').forEach((element) => setupUppy(element));
});

function setupUppy(element) {
  const trigger = element.querySelector('[data-behaviour="uppy-trigger"]');
  const form = element.closest('form');
  const direct_upload_url = document.querySelector('meta[name="direct_upload_url"]').getAttribute('content');
  const field_name = element.dataset.uppy;
  trigger.addEventListener('click', (event) => event.preventDefault());
  const uppy = new Uppy({
    autoProceed: true,
    logger: Uppy.debugLogger
  });

  uppy.use(ActiveStorageUpload, {
    directUploadUrl: direct_upload_url
  });
  uppy.use(Dashboard, {
    trigger: trigger
  });

  uppy.on('complete', (result) => {
    result.successful.forEach((file) => {
      const previewContainer = element.querySelector('[data-behaviour="uppy-preview-container"]');
      const preview = previewContainer.querySelector('[data-behaviour="uppy-preview"]');
      // duplicate preview container
      element.prepend(previewContainer.cloneNode(true));

      // remove previewContainer behaviour
      previewContainer.setAttribute('data-behaviour', '');
      previewContainer.classList.remove('hidden');

      // set hidden field
      const field = previewContainer.querySelector('input[type="hidden"]');
      field.setAttribute('name', field_name);
      field.setAttribute('value', file.response.signed_id);

      if (preview) {
        preview.src = file.preview;
      }
    });
    if (form.dataset.submitToggle == 'true') {
      form.submit();
    }
  });
}

// Update the URL based on a new crop id
window.changeCrop = function(id) {
  let href = window.location.href;
  if (href.indexOf('/crops/') >= 0) {
    href = href.replace(/\/crops\/[0-9]*/, '/crops/' + id);
    // We don't know what the ID is of the preferred plan, so just go back to the dashboard
    if (href.indexOf('/plans/') >= 0) {
      href = href.replace(/\/plans\/.*/, '');
    }
  } else {
    href = '/crops/' + id;
  }
  window.location.href = href;
};


// ChartJS tooltips with extra distance
Tooltip.positioners.nearestWithPadding = function(items, eventPosition) {
  const pos = Tooltip.positioners.nearest(items, eventPosition);

  // Happens when nothing is found
  if (pos === false) {
    return false;
  }

  const chart = this.chart;

  // If we are on the right half, make a tooltip on the left
  if (pos.x > chart.width / 2) {
    return {
      x: pos.x - 40,
      y: chart.height / 2,
      xAlign: 'right',
      yAlign: 'center'
    };
  } else {
    return {
      x: pos.x + 40,
      y: chart.height / 2,
      xAlign: 'left',
      yAlign: 'center'
    };
  }
};
