function add_patch_to_realized_row_form(form, add_week_number = true) {
  // Add a _method patch input field to a create form to change it to an update form
  const el = document.createElement('input');
  el.type = 'hidden';
  el.name = '_method';
  el.value = 'patch';
  form.appendChild(el);
  if (add_week_number) {
    const week_number = document.getElementById('realized_row_week_number').value;
    form.action += '/' + week_number;
  }
}

window.form_funcs = {
  add_patch_to_realized_row_form: add_patch_to_realized_row_form
};

/* Suggestions */
window.Alpine.magic('realized_row_suggestions', () => {
  return (crop_data, var_name, custom = false) => {
    const map = new Map();
    Object.keys(crop_data).forEach((key) => {
      let base = crop_data[key]['realized_row'];
      if (custom) base = base['custom_vars'];

      const val = base[var_name];
      const crop_name = crop_data[key]['name'];

      if (!val) return;

      if (map.has(val)) {
        map.set(val, map.get(val) + ', ' + crop_name);
      } else {
        map.set(val, crop_name);
      }
    });
    // note that the below orders by number of commas, which gets funky if crop names contain many commas.
    return Array.from(map.entries()).sort((a, b) => b[1].split(',').length - a[1].split(',').length).slice(0, 4);
    // converting to array because map.map did not seem to work
  };
});

function suggestionInput() {
  return {
    generateSuggestions(suggestions = []) {
      let suggestionsDiv = this.$el.parentNode.querySelector('.suggestions');
      if (!suggestionsDiv) {
        suggestionsDiv = document.createElement('div');
        suggestionsDiv.className = 'suggestions absolute inset-y-0 right-0 flex py-1.5 pr-1.5';
        this.$el.after(suggestionsDiv);
      }

      suggestionsDiv.innerHTML = suggestions.map(([value, label]) =>
        `<div
          class="inline-flex items-center rounded border border-gray-200 ml-1 px-1
          font-sans text-xs text-gray-400 cursor-pointer hover:bg-gray-100"
          x-data
          @click="e = $el.parentNode.parentNode.querySelector('input');
          if (e.value != '${value}') { e.value = '${value}'; e.dispatchEvent(new Event('change', { bubbles: true })); }"
          title='${label}'
        ><span class="pointer-events-none">${value}</span></div>`
      ).join('');
    }
  };
}

function dynamicNewObjectFields(containerId, placeHolderId, newId) {
  const placeHolderField = document.querySelector(containerId);
  const newFieldset = placeHolderField.outerHTML.replace(RegExp(placeHolderId, 'g'), newId);
  placeHolderField.insertAdjacentHTML('afterend', newFieldset);
  placeHolderField.remove();
}

function AddingNewStems(stem_names, new_stems, stem_save_status, split = false) {
  let new_stem_name;
  if (split) {
    const split_num = String(String(split).match(/^-?\d+/));
    const last_split = stem_names.filter((item) => item.startsWith(split_num)).sort().at(-1);
    if (/^-?\d+$/.test(last_split)) {
      new_stem_name = String(split) + 'a';
    } else if (/^-?\d+[a-z]$/.test(last_split)) {
      const num = String(last_split.match(/^-?\d+/));
      const letter = String(last_split.match(/[a-z]/));
      new_stem_name = num + nextChar(letter);
    }
  } else if (stem_names.length == 0) {
    new_stem_name = '1';
  } else {
    new_stem_name = Number(stem_names.sort().at(-1).match(/^-?\d+/)) + 1;
    new_stem_name = String(new_stem_name);
  }
  stem_names.push(new_stem_name);
  new_stems.push({id: (stem_names.length - 1), name: new_stem_name});
  stem_save_status[new_stem_name] = 'new';
}

function nextChar(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1);
}

function summarize_registration_vars(arr, name, custom = false) {
  const val_list = arr.map((obj) => {
    if (custom) {
      obj = obj['current']['custom_vars'];
    }
    return obj[name];
  }).filter((num) => {
    return num !== undefined && num !== null && num !== '';
  });
  let mean_val = '';
  if (val_list.length > 0) {
    mean_val = val_list.reduce((sum, num) => sum + parseFloat(num), 0) / val_list.length;
    mean_val = mean_val.toFixed(2);
  }
  return mean_val;
}

function summarize_truss_dev(realized_row) {
  const f_stem_rows = get_filtered_stem_rows(realized_row);
  let avg_truss_dev = '';
  if (f_stem_rows.length > 0) {
    avg_truss_dev = f_stem_rows.reduce((sum, stem_row) => {
      let my_stem_sum = 0;
      my_stem_sum = stem_row.reduce((stem_sum, truss_row) => {
        const c_set_fruit = parseFloat(truss_row['set_fruits']);
        const c_expected_buds = parseFloat(truss_row['expected_buds']);
        let p_set_fruit = 0;
        if (truss_row['previous'] !== null && truss_row['previous'] !== undefined) {
          p_set_fruit = parseFloat(truss_row['previous']['set_fruits']);
        }
        return stem_sum + (c_set_fruit - p_set_fruit) / c_expected_buds;
      }, 0);
      return sum + my_stem_sum;
    }, 0) / f_stem_rows.length;
    avg_truss_dev = avg_truss_dev.toFixed(2);
    console.log(avg_truss_dev);
  }
  return avg_truss_dev;
}

function summarize_fruit_set(realized_row) {
  const f_stem_rows = get_filtered_stem_rows(realized_row);
  let avg_fruit_set = 0;
  if (f_stem_rows.length > 0 && realized_row['stem_density']) {
    avg_fruit_set = f_stem_rows.reduce((sum, stem_row) => {
      let my_stem_sum = 0;
      my_stem_sum = stem_row.reduce((stem_sum, truss_row) => {
        const c_set_fruit = parseFloat(truss_row['set_fruits']);
        let p_set_fruit = 0;
        if (truss_row['previous'] !== null && truss_row['previous'] !== undefined) {
          p_set_fruit = parseFloat(truss_row['previous']['set_fruits']);
        }
        return stem_sum + (c_set_fruit - p_set_fruit);
      }, 0);
      return sum + my_stem_sum;
    }, 0) * realized_row['stem_density'] / f_stem_rows.length;
    avg_fruit_set = avg_fruit_set.toFixed(2);
    console.log(avg_fruit_set);
  }
  return avg_fruit_set;
}

function get_filtered_stem_rows(realized_row) {
  const stem_rows = realized_row.cf_rows.map((cf_row) => {
    return cf_row.stem_rows.map((stem_row) => {
      return stem_row.truss_rows.filter((truss_row) => {
        let p_set_fruit = 0;
        if (truss_row['previous'] !== null && truss_row['previous'] !== undefined) {
          p_set_fruit = truss_row['previous']['set_fruits'];
        }
        return parseFloat(truss_row['set_fruits']) > parseFloat(p_set_fruit);
      });
    });
  }).flat();
  const f_stem_rows = stem_rows.filter((stem_row) => {
    return stem_row.length > 0;
  });
  return f_stem_rows;
}

function summarize_grow_period(realized_row) {
  const f_truss_rows = realized_row.cf_rows.map((cf_row) => {
    return cf_row.stem_rows.map((stem_row) => {
      return stem_row.truss_rows.filter((truss_row) => {
        return truss_row['harvested'] && truss_row.expected_buds == truss_row.set_fruits;
      });
    }).flat();
  }).flat();
  let avg_grow_period = '';
  if (f_truss_rows.length > 0) {
    avg_grow_period = f_truss_rows.reduce((sum, truss_row) => {
      let fully_set = truss_row['fully_set'];
      if ([undefined, null, ''].includes(fully_set)) {
        fully_set = realized_row.week_number;
      }
      return sum + 7 * (realized_row.week_number - fully_set);
    }, 0) / f_truss_rows.length;
    avg_grow_period = avg_grow_period.toFixed(2);
  }
  console.log(avg_grow_period);
  return avg_grow_period;
}

window.summarize_grow_period = summarize_grow_period;
window.summarize_fruit_set = summarize_fruit_set;
window.summarize_truss_dev = summarize_truss_dev;
window.summarize_registration_vars = summarize_registration_vars;
window.AddingNewStems = AddingNewStems;
window.suggestionInput = suggestionInput;
window.dynamicNewObjectFields = dynamicNewObjectFields;
